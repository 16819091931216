body {
    background-color: #e6ebe9;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.page-container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    width: 100%;
    min-width: 320px;
    max-width: 960px;
}

.default-content-container {
    background-color: #ffffff;
    margin: 0px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.default-large-text {
    font-size: 16px;
    color: #2C4969;
}

.default-text {
    font-size: 14px;
    color: #2C4969;
}
