.Home-container {
    display: flex;
    flex-direction: column;
}

.Home-zone {
    flex: 0 0 auto;
}

.Home-zone-center {
    flex: 0 0 auto;
    align-self: center;
}

.Home-cover-image {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    max-width: 480px;
    height: auto;
}
