body {
    background-color: #e6ebe9;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.page-container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    width: 100%;
    min-width: 320px;
    max-width: 960px;
}

.default-content-container {
    background-color: #ffffff;
    margin: 0px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.default-large-text {
    font-size: 16px;
    color: #2C4969;
}

.default-text {
    font-size: 14px;
    color: #2C4969;
}

.Header-container {
    background-image: url(/static/media/header.5136d9e3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
}

.Header-title {
    font-size: 32px;
    font-weight: bold;
    color: #2C4969;
}

.Header-ball-logo {
    -webkit-animation: ball-logo-spin infinite 3s linear;
            animation: ball-logo-spin infinite 3s linear;
    height: 40px;
}

@-webkit-keyframes ball-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes ball-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Menu-container {
    background-color: #dddddd;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.Menu-item {
    flex: 1 1 33.33%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    margin: 3px;
}

.Menu-item-background {
    background-color: #f6f6f6;
    color: #73767a;
}

.Menu-item-background:hover {
    color: #29c085;
}

.Menu-item-selected-background {
    background-color: #29c085;
    color: #ffffff;
}

.Menu-item-title {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
}

.Menu-item-selection {
    background-color: #57dafb;
    height: 0px;
}

.Home-container {
    display: flex;
    flex-direction: column;
}

.Home-zone {
    flex: 0 0 auto;
}

.Home-zone-center {
    flex: 0 0 auto;
    align-self: center;
}

.Home-cover-image {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    max-width: 480px;
    height: auto;
}

.Home-zone-center {
    flex: 0 0 auto;
    align-self: center;
}

.Contact-map-image {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    height: auto;
}

