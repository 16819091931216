.Menu-container {
    background-color: #dddddd;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.Menu-item {
    flex: 1 1 33.33%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    margin: 3px;
}

.Menu-item-background {
    background-color: #f6f6f6;
    color: #73767a;
}

.Menu-item-background:hover {
    color: #29c085;
}

.Menu-item-selected-background {
    background-color: #29c085;
    color: #ffffff;
}

.Menu-item-title {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
}

.Menu-item-selection {
    background-color: #57dafb;
    height: 0px;
}
