.Header-container {
    background-image: url(./img/header.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
}

.Header-title {
    font-size: 32px;
    font-weight: bold;
    color: #2C4969;
}

.Header-ball-logo {
    animation: ball-logo-spin infinite 3s linear;
    height: 40px;
}

@keyframes ball-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
